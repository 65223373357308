<template>
  <section class="whychoose-1 secpadd">
    <div class="container">
      <div class="row quote1top">
        <div class="col-md-8">
          <div class="fh-section-title clearfix  text-left version-dark paddbtm40">
            <h2>{{ $store.getters['heading/getPortalHeadings'].title }}</h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-sm-12">
          <div class="fh-form request-form">
            <div class="field">
              <label>{{ getTranslation('email') }}<span class="require">*</span></label>
              <input class="form-control" :placeholder="getTranslation('email')" :class="{'is-invalid':$v.form.email.$error}" type="text" v-model.trim="form.email">
              <div v-if="$v.form.email.$error" class="invalid-feedback">
                {{ getTranslation('requiredField') }}
              </div>
            </div>
            <div class="field">
              <label>{{ getTranslation('password') }}<span class="require">*</span></label>
              <input class="form-control" :placeholder="getTranslation('password')" :class="{'is-invalid':$v.form.password.$error}" type="password" v-model.trim="form.password">
              <div v-if="$v.form.password.$error" class="invalid-feedback">
                {{ getTranslation('requiredField') }}
              </div>
            </div>
            <p @click.prevent="login" class="field submit">
              <input :value="getTranslation('signIn')" class="fh-btn" type="submit">
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import required from "vuelidate/lib/validators/required";
import UserFunctions from "@/classes/UserFunctions";

export default {
  name: "Login",
  data() {
    return {
      form: {
        email: '',
        password: ''
      }
    }
  },
  created() {
    this.setPortalHeadings({
      title: this.getTranslation('login'),
      breadcrumbs: [
        {
          label: this.getTranslation('home'),
          route: '/'
        },
        {
          label: this.getTranslation('login'),
        }
      ]
    });
  },
  methods: {
    forgotPassword() {
      this.$store.dispatch('heading/setLastRoute', { name: this.$route.name });

      this.$router.push({ name: 'auth.forgotPassword' })
    },
    login() {

      this.$v.form.$touch();

      if ( this.$v.form.$invalid )
        return;

      this.axios.post(this.$urls.auth.login.url, this.form)
          .then(async response => {

            let viewer = response.data.data.viewer;


            if ( viewer.isClient ) {
              await UserFunctions.setUserToken(response.data.data.token, true);

              await UserFunctions.setAxiosAuthorization(true);

              await this.$store.dispatch('user/setPortalUser', viewer);


              return await this.$router.push({ name: 'portal.client.account' });

            } else if ( viewer.isAgent ) {

              await UserFunctions.setUserToken(response.data.data.token, false);

              await UserFunctions.setAxiosAuthorization(false);

              await this.$store.dispatch('user/setUser', viewer);

              return await this.$router.push({ name: 'admin.home' }).then(() => {
                window.location.reload();
              });

            }

            await UserFunctions.setUserToken(response.data.data.token, false);

            await UserFunctions.setAxiosAuthorization(false);

            await this.$store.dispatch('user/setUser', viewer);

            return await this.$router.push({ name: 'admin.home' }).then(() => {
              window.location.reload();
            });


          }).catch(() => {
      });
    }
  },
  validations: {
    form: {
      password: { required },
      email: { required },
    }
  }
}
</script>

<style scoped>

</style>